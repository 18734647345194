<template>
  <v-app>
    <header>
      <v-container fluid style="background-color: #151f42" class="py-0">
        <v-container class="py-0">
          <v-app-bar color="transparent" dark dense flat>
            <v-toolbar-title class="text-h6"
              >МОНГОЛ УЛСЫН ЭРДМИЙН ИX СУРГУУЛЬ</v-toolbar-title
            >
          </v-app-bar>
        </v-container>
      </v-container>
    </header>
    <v-main>
      <section class="mt-16">
        <v-container>
          <v-row justify="start" class="px-16">
            <v-col cols="12" md="6" lg="6">
              <h1 style="font-weight: bold" class="text-h3 mt-16">
                Академик xөтөлбөрүүд
              </h1>
              <p style="font-weight: 300" class="text-subtitle-1"></p>
              <p
                style="font-weight: 400; line-height: 150%"
                class="text-subtitle-1"
              >
                Доктор, магистр, баклаврын түвшинд мэргэжлийн сургалтуудыг санал
                болгож байна.
              </p>

              <p
                style="font-weight: 400; line-height: 150%"
                class="text-subtitle-1"
              >
                Олон улс судлал, удирдахуйн ухааны докторын зэрэг хамгаалуулах
                зөвлөл
              </p>
              <v-btn
                @click="_goTo"
                rounded
                flat
                color="#b1040e"
                class="white--text"
                elevation="0"
              >
                Бүртгүүлэx
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-img src="h1.jpg"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-main>
    <!-- <section>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-card>
             
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
    <v-footer dark class="" style="background: #151f42">
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="pa-0 pt-4">
          <h1 class="text-center text-h3" style="font-weight: bold">
            Xолбоо бариx
          </h1>
        </v-col>
        <v-col cols="12" class="pa-0">
          <p class="text-center text-caption mt-4">
            Монгол улс, УБ хот, СБД 8-р хороо, Бага тойруу,
            <br />
            Сүхбаатарын талбай 20/6, Эрдмийн ордон
          </p>
        </v-col>
        <v-col>
          <v-row justify="center" class="py-4">
            <v-btn icon x-large color="primary">
              <v-icon>mdi-twitter</v-icon>
              <!-- http://gradedu.mn/# -->
            </v-btn>
            <v-btn icon x-large color="primary" target="blank">
              <a
                style="text-decoration: none"
                href="https://www.facebook.com/graduateuniversity"
                target="blank"
                ><v-icon>mdi-facebook</v-icon></a
              >
            </v-btn>
            <v-btn icon x-large color="primary">
              <a
                style="text-decoration: none"
                href="http://gradedu.mn/#"
                target="blank"
                ><v-icon>mdi-instagram</v-icon></a
              >
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0">
          <h1 class="text-center text-h5" style="font-weight: normal">
            Phone: (+976) 7600-2010, 7600-3010
          </h1>
        </v-col>
        <v-col cols="12" class="pa-1">
          <p class="text-center text-h6">Email: info@gradedu.mn</p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<style>
.title {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center {
  position: relative;
}

.shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent);
}
body {
  line-height: 1.5;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  color: #000;
}
@media only screen and (min-width: 700px) {
  .ungone {
    visibility: visible;
  }
}
@media only screen and (max-width: 700px) {
  .gone {
    visibility: hidden;
  }
}
html {
  font-size: 13px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(13px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 19px;
  }
}
.bg {
  background: rgb(47, 36, 36);
  background: linear-gradient(
    180deg,
    rgba(47, 36, 36, 1) 27%,
    rgba(244, 244, 244, 1) 27%
  );
}
ul li::marker {
  font-size: 100%;
  color: black;
}
@media only screen and (max-width: 2000px) {
  .abs1 {
    margin-top: -10%;
  }
}

@media only screen and (max-width: 1903px) {
  .abs1 {
    margin-top: -18%;
  }
}
@media only screen and (max-width: 1263px) {
  .abs2 {
    margin-top: -32%;
  }
}
@media only screen and (max-width: 959px) {
  .abs3 {
    margin-top: 0%;
  }
}
</style>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: ["Home", "Pricing", "About Us"],
    items1: [
      "News",
      "Events",
      "Academics",
      "Research",
      "Health Care",
      "Campus Life",
      "Admission",
      "About",
    ],
  }),
  methods: {
    _goTo() {
      window.open(
        "https://gradedu-855be.web.app/prestudentaddgradedu/new",
        "_blank"
      );
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>